import { useParams } from "react-router-dom";
import { CollectionCard } from "@/components/CollectionCard";
import { useGroups } from "@/hooks/useGroups";
import { useGroupCollections } from "@/hooks/useGroupCollections";
import { Skeleton } from "@/components/ui/skeleton";

const GroupPage = () => {
  const { groupId } = useParams();
  const { groups, isLoading: groupsLoading } = useGroups();
  const { collections, isLoading: collectionsLoading, error } = useGroupCollections(groupId);
  
  const group = groups?.find(g => g.id === groupId);
  const isLoading = groupsLoading || collectionsLoading;

  if (isLoading) {
    return (
      <div className="container py-8">
        <div className="mb-8">
          <Skeleton className="h-10 w-64 mb-4" />
          <Skeleton className="h-6 w-96" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[1, 2, 3].map((i) => (
            <Skeleton key={i} className="h-[300px] w-full" />
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="container py-8">Error loading group data: {error.message}</div>;
  }

  if (!group) {
    return <div className="container py-8">Group not found</div>;
  }

  return (
    <div className="container py-8">
      <div className="mb-8">
        <h1 className="text-4xl font-bold mb-4">{group.title}</h1>
        <p className="text-lg text-muted-foreground">{group.description}</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {collections?.map((collection) => (
          <CollectionCard key={collection.id} collection={collection} />
        ))}
      </div>
    </div>
  );
};

export default GroupPage;