import { useParams, useSearchParams } from "react-router-dom";
import { collections } from "@/data/mockData";
import { Study } from "@/components/Study";
import { LearningMode } from "@/types";

const StudyPage = () => {
  const { collectionId } = useParams();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode") as LearningMode || "practice";
  const collection = collections.find((c) => c.id === collectionId);

  if (!collection) {
    return <div>Collection not found</div>;
  }

  return (
    <div className="container py-8">
      <h1 className="text-4xl font-bold mb-8 text-center">{collection.title}</h1>
      <Study collection={collection} mode={mode} />
    </div>
  );
};

export default StudyPage;