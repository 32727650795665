import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { useQueryClient } from '@tanstack/react-query';

export const fetchGroupCollections = async (groupId: string) => {
  const { data, error } = await supabase
    .from('group_collections')
    .select(`
      collection_id,
      order_index,
      collections (
        id,
        title,
        description,
        answer_image_url,
        is_free,
        created_at
      )
    `)
    .eq('group_id', groupId)
    .order('order_index');

  if (error) throw error;
  return data?.map(item => item.collections) || [];
};

export const useGroupCollections = (groupId: string | undefined) => {
  const queryClient = useQueryClient();

  const {
    data: collections,
    isLoading,
    error
  } = useQuery({
    queryKey: ['group-collections', groupId],
    queryFn: () => groupId ? fetchGroupCollections(groupId) : Promise.resolve([]),
    enabled: !!groupId
  });

  useEffect(() => {
    if (!groupId) return;

    const channel = supabase
      .channel('group-collections-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'group_collections',
          filter: `group_id=eq.${groupId}`
        },
        () => {
          queryClient.invalidateQueries({ queryKey: ['group-collections', groupId] });
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [groupId, queryClient]);

  return { collections, isLoading, error };
};