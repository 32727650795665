import { useGroups } from "@/hooks/useGroups";
import { useSignsQuery, useCollectionsQuery } from "@/data/mockData";
import { CollectionCard } from "@/components/CollectionCard";
import { GroupCard } from "@/components/GroupCard";
import { Link } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";

const Index = () => {
  const { data: signs, isLoading: signsLoading, error: signsError } = useSignsQuery();
  const { data: collections, isLoading: collectionsLoading, error: collectionsError } = useCollectionsQuery();
  const { groups, isLoading: groupsLoading, error: groupsError } = useGroups();

  const isLoading = signsLoading || collectionsLoading || groupsLoading;
  const error = signsError || collectionsError || groupsError;

  if (isLoading) {
    return (
      <div className="container py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[1, 2, 3].map((i) => (
            <Skeleton key={i} className="h-[300px] w-full" />
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container py-8">
        <div className="text-red-500">Error loading data: {error.message}</div>
      </div>
    );
  }

  const freeCollections = collections?.filter(collection => collection.is_free) || [];
  const paidCollections = collections?.filter(collection => !collection.is_free) || [];

  return (
    <div className="container py-8">
      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-6">Free Collections</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {freeCollections.map((collection) => (
            <CollectionCard key={collection.id} collection={collection} />
          ))}
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-6">Premium Collections</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {paidCollections.map((collection) => (
            <CollectionCard key={collection.id} collection={collection} />
          ))}
        </div>
      </section>

      <section>
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-3xl font-bold">Learning Groups</h2>
          <Link 
            to="/groups" 
            className="text-primary hover:underline"
          >
            View all groups
          </Link>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {groups?.slice(0, 3).map((group) => (
            <GroupCard key={group.id} group={group} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Index;