import { Collection } from "../types";

export const collections: Collection[] = [
  {
    id: "numbers-1-20",
    title: "Numbers 1-20",
    description: "Learn to count from one to twenty in NZSL",
    answer_image_url: "https://images.unsplash.com/photo-1548175551-1edaea7bbf0d?q=80&w=2797&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    is_free: true,
  },
  {
    id: "alphabet-a-z",
    title: "Alphabet A-Z",
    description: "Learn the NZSL alphabet",
    answer_image_url: "https://plus.unsplash.com/premium_photo-1666739032615-ecbd14dfb543?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    is_free: true,
  },
  {
    id: "basics",
    title: "NZSL Basics",
    description: "Learn essential everyday signs",
    answer_image_url: "https://plus.unsplash.com/premium_photo-1715474788198-7054bd8352ac?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    is_free: true,
  },
  {
    id: "emotions",
    title: "Emotions & Feelings",
    description: "Express yourself in NZSL",
    answer_image_url: "https://plus.unsplash.com/premium_photo-1684979565684-e350fc89a29d?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    is_free: false,
  },
];