import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { fetchSignsByCollectionId } from '@/api/signs';
import { useQueryClient } from '@tanstack/react-query';

export const useCollectionSigns = (collectionId: string | undefined) => {
  const queryClient = useQueryClient();

  const {
    data: signs,
    isLoading,
    error
  } = useQuery({
    queryKey: ['signs', collectionId],
    queryFn: () => collectionId ? fetchSignsByCollectionId(collectionId) : Promise.resolve([]),
    enabled: !!collectionId
  });

  useEffect(() => {
    if (!collectionId) return;

    // Subscribe to changes in collection_signs table
    const channel = supabase
      .channel('collection-signs-changes')
      .on(
        'postgres_changes',
        {
          event: '*', // Listen to all changes (INSERT, UPDATE, DELETE)
          schema: 'public',
          table: 'collection_signs',
          filter: `course_id=eq.${collectionId}`
        },
        () => {
          // Invalidate and refetch the signs query when changes occur
          queryClient.invalidateQueries({ queryKey: ['signs', collectionId] });
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [collectionId, queryClient]);

  return { signs, isLoading, error };
};