import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { supabase } from "@/integrations/supabase/client";
import { Header } from "./components/Header";
import { Breadcrumbs } from "./components/Breadcrumbs";
import Index from "./pages/Index";
import Collection from "./pages/Collection";
import StudyPage from "./pages/StudyPage";
import AllCollections from "./pages/AllCollections";
import GroupPage from "./pages/GroupPage";
import Groups from "./pages/Groups";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 1,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SessionContextProvider supabaseClient={supabase}>
        <TooltipProvider>
          <Toaster />
          <Sonner />
          <BrowserRouter>
            <div className="min-h-screen flex flex-col">
              <Header />
              <Breadcrumbs />
              <main className="flex-1">
                <Routes>
                  <Route path="/" element={<Index />} />
                  <Route path="/collections" element={<AllCollections />} />
                  <Route path="/groups" element={<Groups />} />
                  <Route path="/collection/:collectionId" element={<Collection />} />
                  <Route path="/collection/:collectionId/study" element={<StudyPage />} />
                  <Route path="/group/:groupId" element={<GroupPage />} />
                </Routes>
              </main>
            </div>
          </BrowserRouter>
        </TooltipProvider>
      </SessionContextProvider>
    </QueryClientProvider>
  );
}

export default App;