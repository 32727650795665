import { useState, useEffect } from "react";
import { FlashCard, LearningMode, TestResult, Sign } from "../types";
import { shuffle } from "lodash";

export const useStudyState = (collectionId: string, mode: LearningMode, signs: Sign[]) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [results, setResults] = useState<TestResult[]>([]);
  const [cards, setCards] = useState<FlashCard[]>([]);

  // Update cards when signs data changes
  useEffect(() => {
    const newCards = signs.map(sign => ({
      id: sign.id,
      title: sign.title,
      imageUrl: sign.answer_image_url,
      questionImage: sign.question_image_url,
      dictionaryUrl: sign.dictionary_url
    }));

    // Shuffle cards only in test modes
    if (mode === "signThis" || mode === "whatsThisSign") {
      setCards(shuffle(newCards));
    } else {
      setCards(newCards);
    }
  }, [signs, mode]);

  const handleNext = () => {
    if (currentCardIndex < cards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      if (mode === "signThis" || mode === "whatsThisSign") {
        setShowAnswer(false);
      }
    }
  };

  const handlePrevious = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
    }
  };

  const handleScore = (score: "correct" | "close" | "incorrect") => {
    const newResults = [...results, { cardId: cards[currentCardIndex].id, score }];
    setResults(newResults);
    setShowAnswer(false);
    setCurrentCardIndex(currentCardIndex + 1);
  };

  const handleRetry = () => {
    setCurrentCardIndex(0);
    setResults([]);
    setShowAnswer(false);
    // Reshuffle cards when retrying in test modes
    if (mode === "signThis" || mode === "whatsThisSign") {
      setCards(prevCards => shuffle([...prevCards]));
    }
  };

  return {
    currentCardIndex,
    showAnswer,
    setShowAnswer,
    results,
    cards,
    handleNext,
    handlePrevious,
    handleScore,
    handleRetry
  };
};