import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LearningMode } from "@/types";
import { Brain, PencilRuler, Eye } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";
import { fetchSignsByCollectionId, fetchCollections } from "@/api/signs";

const Collection = () => {
  const { collectionId } = useParams();
  const navigate = useNavigate();

  const { 
    data: signs, 
    isLoading: signsLoading, 
    error: signsError 
  } = useQuery({
    queryKey: ['signs', collectionId],
    queryFn: () => collectionId ? fetchSignsByCollectionId(collectionId) : Promise.resolve([]),
    enabled: !!collectionId
  });

  const { 
    data: collections, 
    isLoading: collectionsLoading, 
    error: collectionsError 
  } = useQuery({
    queryKey: ['collections'],
    queryFn: fetchCollections
  });

  const isLoading = signsLoading || collectionsLoading;
  const error = signsError || collectionsError;
  const collection = collections?.find((c) => c.id === collectionId);

  if (isLoading) {
    return (
      <div className="container py-8">
        <Skeleton className="h-12 w-64 mb-8" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <Skeleton className="h-10" />
          <Skeleton className="h-10" />
          <Skeleton className="h-10" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[1, 2, 3].map((i) => (
            <Skeleton key={i} className="h-[300px]" />
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container py-8">
        <div className="text-red-500">Error loading data: {error.message}</div>
      </div>
    );
  }

  if (!collection) {
    return <div>Collection not found</div>;
  }

  const startLearning = (mode: LearningMode) => {
    navigate(`/collection/${collectionId}/study?mode=${mode}`);
  };

  return (
    <div className="container py-8">
      <div className="flex flex-col gap-4 mb-8">
        <h1 className="text-4xl font-bold">{collection.title}</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Button 
            onClick={() => startLearning("practice")}
            className="flex items-center gap-2"
          >
            <Brain className="w-4 h-4" />
            Practice
          </Button>
          <Button 
            variant="secondary"
            onClick={() => startLearning("signThis")}
            className="flex items-center gap-2"
          >
            <PencilRuler className="w-4 h-4" />
            Test: Sign This
          </Button>
          <Button 
            variant="secondary"
            onClick={() => startLearning("whatsThisSign")}
            className="flex items-center gap-2"
          >
            <Eye className="w-4 h-4" />
            Test: What's This Sign?
          </Button>
        </div>
      </div>
      
      <p className="text-lg text-muted-foreground mb-8">{collection.description}</p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {signs && signs.map((sign) => (
          <Card key={sign.id}>
            <CardHeader>
              <CardTitle>{sign.title}</CardTitle>
            </CardHeader>
            <CardContent>
              <img
                src={sign.answer_image_url}
                alt={sign.title}
                className="w-full h-48 object-contain rounded-md"
              />
              <a
                href={sign.dictionary_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline block mt-4"
              >
                View in NZSL Dictionary
              </a>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Collection;