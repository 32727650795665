import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { useQueryClient } from '@tanstack/react-query';
import { Group } from '@/types';

export const fetchGroups = async () => {
  const { data, error } = await supabase
    .from('groups')
    .select('*')
    .order('order_index');

  if (error) throw error;
  return data;
};

export const useGroups = () => {
  const queryClient = useQueryClient();

  const {
    data: groups,
    isLoading,
    error
  } = useQuery({
    queryKey: ['groups'],
    queryFn: fetchGroups
  });

  useEffect(() => {
    const channel = supabase
      .channel('groups-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'groups'
        },
        () => {
          queryClient.invalidateQueries({ queryKey: ['groups'] });
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [queryClient]);

  return { groups, isLoading, error };
};