import { Collection } from "../types";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { Lock } from "lucide-react";
import { useSession } from "@supabase/auth-helpers-react";
import { cn } from "@/lib/utils";
import { useCollectionSigns } from "@/hooks/useCollectionSigns";
import { Skeleton } from "./ui/skeleton";

interface CollectionCardProps {
  collection: Collection;
}

export const CollectionCard = ({ collection }: CollectionCardProps) => {
  const navigate = useNavigate();
  const session = useSession();
  const { signs, isLoading } = useCollectionSigns(collection.id);
  const isAccessible = collection.is_free || session;

  return (
    <Card 
      className={cn(
        "relative transition-shadow duration-300",
        isAccessible && "cursor-pointer hover:shadow-lg"
      )}
      onClick={() => isAccessible && navigate(`/collection/${collection.id}`)}
    >
      {!isAccessible && (
        <div className="absolute inset-0 bg-black/50 flex items-center justify-center rounded-lg z-10">
          <Lock className="w-8 h-8 text-white" />
        </div>
      )}
      <CardHeader>
        <CardTitle className="text-xl font-bold">
          {collection.title}
          {collection.is_free && (
            <span className="ml-2 text-sm font-normal text-green-600">Free</span>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <img 
          src={collection.answer_image_url} 
          alt={collection.title} 
          className="w-full h-48 object-cover rounded-md mb-4"
        />
        <p className="text-muted-foreground">{collection.description}</p>
        {isLoading ? (
          <Skeleton className="h-6 w-24 mt-2" />
        ) : (
          <p className="text-sm text-primary mt-2">{signs?.length || 0} cards</p>
        )}
      </CardContent>
    </Card>
  );
};