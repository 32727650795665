import { Group } from "@/types";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { useGroupCollections } from "@/hooks/useGroupCollections";
import { Skeleton } from "./ui/skeleton";
import { Users } from "lucide-react";

interface GroupCardProps {
  group: Group;
}

export const GroupCard = ({ group }: GroupCardProps) => {
  const navigate = useNavigate();
  const { collections, isLoading } = useGroupCollections(group.id);

  return (
    <Card 
      className="cursor-pointer transition-shadow duration-300 hover:shadow-lg"
      onClick={() => navigate(`/group/${group.id}`)}
    >
      <CardHeader>
        <CardTitle className="text-xl font-bold flex items-center gap-2">
          <Users className="w-5 h-5" />
          {group.title}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <img 
          src={group.image_url || '/placeholder.svg'} 
          alt={group.title} 
          className="w-full h-48 object-cover rounded-md mb-4"
        />
        <p className="text-muted-foreground">{group.description}</p>
        {isLoading ? (
          <Skeleton className="h-6 w-24 mt-2" />
        ) : (
          <p className="text-sm text-primary mt-2">{collections?.length || 0} collections</p>
        )}
      </CardContent>
    </Card>
  );
};