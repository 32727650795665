import { supabase } from "@/integrations/supabase/client";

export const fetchSigns = async () => {
  const { data, error } = await supabase
    .from('signs')
    .select('*')
    .order('title');

  if (error) throw error;
  return data;
};

export const fetchCollections = async () => {
  const { data, error } = await supabase
    .from('collections')
    .select('*')
    .order('title');

  if (error) throw error;
  return data;
};

export const fetchCollectionSigns = async () => {
  const { data, error } = await supabase
    .from('collection_signs')
    .select('*')
    .order('order_index');

  if (error) throw error;
  return data;
};

export const fetchSignsByCollectionId = async (collectionId: string) => {
  const { data, error } = await supabase
    .from('collection_signs')
    .select(`
      sign_id,
      order_index,
      signs (
        id,
        title,
        answer_image_url,
        question_image_url,
        dictionary_url,
        created_at
      )
    `)
    .eq('course_id', collectionId)
    .order('order_index');

  if (error) throw error;
  return data?.map(item => item.signs) || [];
};