import { Sign, Collection } from "../types";
import { collections } from "./collections";
import { collectionSigns } from "./collectionSigns";
import { useQuery } from "@tanstack/react-query";
import { fetchSigns, fetchCollections, fetchCollectionSigns } from "@/api/signs";

export const useSignsQuery = () => {
  return useQuery({
    queryKey: ['signs'],
    queryFn: fetchSigns,
  });
};

export const useCollectionsQuery = () => {
  return useQuery({
    queryKey: ['collections'],
    queryFn: fetchCollections,
  });
};

export const useCollectionSignsQuery = () => {
  return useQuery({
    queryKey: ['collection_signs'],
    queryFn: fetchCollectionSigns,
  });
};

export const getSignsForCollection = (collectionId: string, signs: Sign[]) => {
  return collectionSigns
    .filter(cs => cs.collection_id === collectionId)
    .sort((a, b) => a.order_index - b.order_index)
    .map(s => signs.find(sign => sign.id === s.sign_id)!)
    .filter(Boolean);
};

// For backward compatibility with existing components
export const mockCollections = (signs: Sign[]) => collections.map(collection => ({
  ...collection,
  cards: getSignsForCollection(collection.id, signs).map(sign => ({
    id: sign.id,
    title: sign.title,
    imageUrl: sign.answer_image_url,
    questionImage: sign.question_image_url,
    dictionaryUrl: sign.dictionary_url,
  })),
}));

export { collections };