import { Collection, LearningMode } from "../types";
import { StudyProgress } from "./study/StudyProgress";
import { StudyCard } from "./study/StudyCard";
import { StudyControls } from "./study/StudyControls";
import { Results } from "./study/Results";
import { useStudyState } from "@/hooks/useStudyState";
import { useCollectionSigns } from "@/hooks/useCollectionSigns";
import { Skeleton } from "./ui/skeleton";

interface StudyProps {
  collection: Collection;
  mode: LearningMode;
}

export const Study = ({ collection, mode }: StudyProps) => {
  const { signs, isLoading, error } = useCollectionSigns(collection.id);
  
  const {
    currentCardIndex,
    showAnswer,
    setShowAnswer,
    results,
    cards,
    handleNext,
    handlePrevious,
    handleScore,
    handleRetry
  } = useStudyState(collection.id, mode, signs || []);

  if (isLoading) {
    return (
      <div className="max-w-3xl mx-auto space-y-4">
        <Skeleton className="h-2 w-full" />
        <Skeleton className="h-[400px] w-full" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-3xl mx-auto text-center text-red-500">
        Error loading signs: {error.message}
      </div>
    );
  }

  // Show results when we've gone through all cards
  if (currentCardIndex >= cards.length) {
    return (
      <Results 
        results={results} 
        collectionId={collection.id} 
        onRetry={handleRetry} 
      />
    );
  }

  const currentCard = cards[currentCardIndex];

  return (
    <div className="max-w-3xl mx-auto">
      <StudyProgress
        currentIndex={currentCardIndex}
        total={cards.length}
        mode={mode}
      />

      <StudyCard
        card={currentCard}
        mode={mode}
        showAnswer={mode === "practice" || showAnswer}
        onShowAnswer={() => setShowAnswer(true)}
        onScore={mode !== "practice" ? handleScore : undefined}
      />

      {mode === "practice" && (
        <StudyControls
          onPrevious={handlePrevious}
          onNext={handleNext}
          isFirstCard={currentCardIndex === 0}
          isLastCard={currentCardIndex === cards.length - 1}
        />
      )}
    </div>
  );
};